<template>
    <v-dialog v-model="show" persistent max-width="400" scrollable>
        <v-card>
            <v-card-title>Add Site</v-card-title>
            <v-card-text class="mt-3">
                <v-text-field
                    v-model="form.title"
                    :error-messages="form.errors.get('title')"
                    @input="form.errors.clear('title')"
                    label="Name (Required)"
                    autofocus
                />
                <v-text-field
                    v-model="form.description"
                    :error-messages="form.errors.get('description')"
                    @input="form.errors.clear('description')"
                    label="Description"
                />
                <v-select
                    v-model="form.types"
                    :items="types"
                    :menu-props="{ maxHeight: '400' }"
                    item-text="title"
                    item-value="id"
                    label="Type (Required)"
                    multiple
                    chips
                    :error-messages="form.errors.get('types')"
                    @input="form.errors.clear('types')"
                />
                <v-select
                    v-model="form.country_id"
                    :items="countries"
                    :menu-props="{ maxHeight: '400' }"
                    item-text="name"
                    item-value="id"
                    label="Country (Required)"
                    :error-messages="form.errors.get('country_id')"
                    @input="form.errors.clear('country_id')"
                    @change="handleCountryChanged"
                />
                <v-text-field
                    v-model="form.street_one"
                    :error-messages="form.errors.get(`street_one`)"
                    @input="form.errors.clear(`street_one`)"
                    label="Address line 1 (Required)"
                />
                <v-text-field
                    v-model="form.street_two"
                    :error-messages="form.errors.get(`street_two`)"
                    @input="form.errors.clear(`street_two`)"
                    label="Address line 2"
                />
                <v-text-field
                    v-model="form.suburb"
                    :error-messages="form.errors.get(`suburb`)"
                    @input="form.errors.clear(`suburb`)"
                    label="Suburb (Required)"
                />
                <v-select
                    v-model="form.region_id"
                    :items="regions"
                    :menu-props="{ maxHeight: '400' }"
                    item-text="name"
                    item-value="id"
                    label="State/Province (Required)"
                    :error-messages="form.errors.get(`region_id`)"
                    @input="form.errors.clear(`region_id`)"
                    no-data-text="Select a country first."
                />
                <v-text-field
                    v-model="form.postcode"
                    :error-messages="form.errors.get(`postcode`)"
                    @input="form.errors.clear(`postcode`)"
                    label="ZIP/Postal code (Required)"
                />
                <app-map :address="addressToString(form)" @position="handleMapPosition($event)" />
            </v-card-text>
            <v-card-actions class="pt-4">
                <v-spacer />
                <v-btn small :disabled="saving" @click="show = false">Cancel</v-btn>
                <v-btn small color="primary" :loading="saving" @click="save">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Form from '@/utils/form';
import Http from '@/utils/httpClient';
import AppMap from '@/components/Map.vue';

export default {
    components: {
        AppMap
    },
    data() {
        return {
            saving: false,
            show: false,
            countries: [],
            regions: [],
            addresses: [],
            types: [],
            form: new Form({
                title: null,
                types: null,
                description: null,
                country_id: null,
                street_one: null,
                street_two: null,
                suburb: null,
                region_id: null,
                postcode: null,
                lat: null,
                lon: null
            })
        };
    },
    methods: {
        open(countries, addresses, types) {
            this.countries = countries;
            this.addresses = addresses;
            this.types = types;
            this.form.reset();
            this.show = true;
        },
        handleCountryChanged(id) {
            this.regions = this.countries.find((country) => country.id === id).regions;
        },
        addressToString(address) {
            const country = this.countries.find((c) => c.id === address.country_id);
            const region = this.regions.find((r) => r.id === address.region_id);

            return `${address.street_one || ''} ${address.street_two || ''} ${address.suburb ||
                ''} ${address.postcode || ''} ${region ? region.name : ''} ${
                country ? country.name : ''
            }`
                .replace(/\s+/g, ' ')
                .trim();
        },
        handleMapPosition(position) {
            this.form.lat = position.lat;
            this.form.lon = position.lng;
        },
        save() {
            this.saving = true;
            Http.post('sites', this.form.payload())
                .then(({ data }) => {
                    this.saving = false;
                    this.show = false;
                    this.$root.$snackbar.open({
                        text: `Site ${this.form.title} was successfully added.`,
                        type: 'success'
                    });
                    this.$emit('success', data);
                })
                .catch(({ response }) => {
                    this.saving = false;
                    this.form.errors.record(response.data);
                });
        }
    }
};
</script>
